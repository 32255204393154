import React, { useState, useEffect } from "react"
import EarthquakeLayout from '../../components/earthquake/earthquakeLayout'


const Earthquake = () => {
  const [eqData, setEqData] = useState([]);
  const [hasError, setHasError] = useState([false]);

  let date = new Date();
  date.setDate(date.getDate() - 15);
  // date for past 15 days
  date = date.toISOString()

  useEffect(() => {
    const requestUrl = "https://stoic-leavitt-e958a3.netlify.app/.netlify/functions/earthquake-proxy"; // New proxy URL

    fetch(requestUrl)
      .then(response => {
        if (!response.ok) {
          setHasError(true);
        }
        return response.json();
      })
      .then(resultData => {
        setEqData(resultData.features.length > 10 ? resultData.features.slice(0, 10) : resultData.features);
      })
      .catch(error => {
        console.log("Error fetching earthquake data:", error);
      });
  }, []);

  return (
    <>
      {typeof window !== 'undefined' && EarthquakeLayout && <EarthquakeLayout
        title={"Los Angeles Earthquakes"}
        hasError={hasError}
        eqData={eqData}>
      </EarthquakeLayout>}
    </>

  );
};

export default Earthquake
